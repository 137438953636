import { graphql, useStaticQuery, Link } from "gatsby"
import React, { useState } from "react"

function Footer() {
  return (
    <footer className="bg-quod-purple-700">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  Quod
                </h4>
                <ul className="mt-4">
                  <li>
                    <a
                      href="/features"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Features
                    </a>
                  </li>
                  <li className="mt-4">
                    <a
                      href="/pricing"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      {" "}
                      Pricing
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  Support
                </h4>
                <ul className="mt-4">
                  <li>
                    <a
                      href="/help"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Help center
                    </a>
                  </li>
                  <li className="mt-4">
                    <a
                      href="/faqs"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      FAQs
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  Company
                </h4>
                <ul className="mt-4">
                  <li>
                    <a
                      href="https://www.dreamm.co.uk/about"
                      target="_blank"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Meet dreamm
                    </a>
                  </li>
                  <li className="mt-4">
                    <a
                      href="http://dreamm.co.uk/learner-management/atmos"
                      target="_blank"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Atmos
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  Legal
                </h4>
                <ul className="mt-4">
                  <li>
                    <a
                      href="http://dreamm.co.uk/legal"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Privacy
                    </a>
                  </li>
                  <li className="mt-4">
                    <a
                      href="/terms"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Terms
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-16 xl:mt-0">
            <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
              Subscribe to our newsletter
            </h4>
            <p className="mt-4 text-gray-300 text-base leading-6">
              The latest Quod news, hints and tips, customer success stories,
              and best practice, sent to your inbox, never more often than once
              a month and only if you opt-in.
            </p>
            <form
              className="mt-4 sm:flex sm:max-w-md"
              method="post"
              action="https://list.dreamm.co.uk/subscription/form"
            >
              <input
                type="hidden"
                name="l"
                value="abfb6abb-f0f7-4d06-90ff-866e5da7a9db"
              />

              <input
                aria-label="Email address"
                name="email"
                type="email"
                required
                className="appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-whitey placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out"
                placeholder="Enter your email"
              />

              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-quod-green-600 hover:bg-quod-green-400 focus:outline-none focus:bg-quod-green-500 transition duration-150 ease-in-out">
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex md:order-2">
            <a
              href="https://www.facebook.com/dreammteam/"
              className="text-gray-400 hover:text-gray-300"
              target="_blank"
            >
              <span className="sr-only">Facebook</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>

            <a
              href="https://twitter.com/dreamm_ltd"
              className="ml-6 text-gray-400 hover:text-gray-300"
              target="_blank"
            >
              <span className="sr-only">Twitter</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
          </div>
          <p className="mt-8 text-base leading-6 text-gray-500 md:mt-0 md:order-1">
            <img
              src={require("../images/dreamm-circle.png")}
              className={"w-6 md:w-8 xl:w-12 inline-block mr-4"}
            />{" "}
            made with <span className={"text-gray-300"}>❤️</span>️ by{" "}
            <a
              href="https://www.dreamm.co.uk"
              target="_blank"
              className={"text-gray-300"}
            >
              dreamm
            </a>{" "}
            © 2020
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
